import React from "react";
import {
    MdOutlineArrowBackIosNew,
    MdOutlineArrowForwardIos,
} from "react-icons/md";
import { Carousel } from "react-responsive-carousel";

const SimpleCarousel = ({ images }) => {
    return (
        <Carousel
            autoPlay={true}
            className="rounded-4xl"
            emulateTouch={false}
            infiniteLoop={true}
            interval={3000}
            renderArrowPrev={(clickHandler) => (
                <button
                    className="absolute z-10 top-1/2 left-4"
                    onClick={clickHandler}
                    style={{ transform: "translateY(-16px)" }} // Compensate half of 32px of the icon
                >
                    <MdOutlineArrowBackIosNew
                        size={32}
                        className="text-white"
                    />
                </button>
            )}
            renderArrowNext={(clickHandler) => (
                <button
                    className="absolute z-10 top-1/2 right-4"
                    onClick={clickHandler}
                    style={{ transform: "translateY(-16px)" }} // Compensate half of 32px of the icon
                >
                    <MdOutlineArrowForwardIos
                        size={32}
                        className="text-white"
                    />
                </button>
            )}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            swipeable={false}
            transitionTime={0} // Prevent the swipe to ensure that the all the corners are always rounded (even during transiction)
        >
            {images}
        </Carousel>
    );
};

export default SimpleCarousel;
