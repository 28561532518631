import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { Trans } from "react-i18next";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import Separator from "../../components/Separator";
import SimpleCarousel from "../../components/SimpleCarousel";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "page-camere"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Camere() {
    const { t } = useTranslation();

    // Define common properties reusable for each image of carousel
    const sharedCarouselImageProperties = {
        aspectRatio: 16 / 10,
        className: "rounded-4xl",
        layout: "fullWidth",
        transformOptions: {
            fit: "cover",
        },
    };
    const images = [
        <StaticImage
            key={0}
            src="../../../assets/images/camere/parete-naturale.jpg"
            alt="Parete naturale"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={1}
            src="../../../assets/images/camere/vista-basilica.jpg"
            alt="Vista Basilica"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={2}
            src="../../../assets/images/camere/camera-quadrupla-con-lucernaio.jpg"
            alt="Camera quadrupla con lucernaio"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={3}
            src="../../../assets/images/camere/camera-doppia-vista-basilica.jpg"
            alt="Camera doppia vista Basilica"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={4}
            src="../../../assets/images/camere/camera-quadrupla.jpg"
            alt="Camera quadrupla"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={5}
            src="../../../assets/images/camere/camera-tripla.jpg"
            alt="Camera tripla"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={6}
            src="../../../assets/images/camere/camera-doppia.jpg"
            alt="Camera doppia"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={7}
            src="../../../assets/images/camere/camera-tripla-piano-terra.jpg"
            alt="Camera tripla piano terra"
            {...sharedCarouselImageProperties}
        />,
    ];

    return (
        <Layout>
            <Seo title={t("page-camere:theRooms")} />
            <Container>
                <h1 className="mt-8">{t("page-camere:theStructure:title")}</h1>
                <p>
                    <Trans
                        i18nKey="page-camere:theStructure:description:1"
                        t={t}
                        components={{ bold: <b /> }}
                    />
                </p>
                <p>
                    <Trans
                        i18nKey="page-camere:theStructure:description:2"
                        t={t}
                    />
                </p>
                <p>
                    <Trans
                        i18nKey="page-camere:theStructure:description:3"
                        t={t}
                    />
                </p>
                <p>
                    <Trans
                        i18nKey="page-camere:theStructure:description:4"
                        t={t}
                        components={{ bold: <b /> }}
                    />
                </p>
                <p>
                    <Trans
                        i18nKey="page-camere:theStructure:description:5"
                        t={t}
                    />
                </p>
                <p>
                    <Trans
                        i18nKey="page-camere:theStructure:description:6"
                        t={t}
                        components={{ bold: <b /> }}
                    />
                </p>
                <p>
                    <Trans
                        i18nKey="page-camere:theStructure:description:7"
                        t={t}
                        components={{ bold: <b /> }}
                    />
                </p>

                <Separator />

                <SimpleCarousel images={images} />
                <h1 className="mt-8">{t("page-camere:theRooms")}</h1>
                <p>
                    <Trans
                        i18nKey="page-camere:description:1"
                        t={t}
                        components={{ bold: <b /> }}
                    />
                </p>
                <p>
                    <Trans
                        i18nKey="page-camere:description:2"
                        t={t}
                        components={{ bold: <b /> }}
                    />
                </p>
                <p>
                    <Trans
                        i18nKey="page-camere:description:3"
                        t={t}
                        components={{ bold: <b /> }}
                    />
                </p>
                <div className="my-6 text-center">
                    <a href="https://booking.slope.it/c8e1dbe3-ddea-4ced-a93e-27bb95d256c0">
                        <Button
                            text={t("page-camere:exploreRatesAndAvailability")}
                        />
                    </a>
                </div>
            </Container>
        </Layout>
    );
}
